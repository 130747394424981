/* You can add global styles to this file, and also import other style files */
@import '~ngx-toastr/toastr.css';
@import "primeicons/primeicons.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
body{
    margin: 0px;
}
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

.toast-top-center{
    align-self: center;
}

.cdk-overlay-pane{
    position:absolute !important;
}